.about-con{
    padding: 60px 20px;
    height: auto;
    background-color: #FFFF00;
    font-family: 'DM Sans', sans-serif;
}
.about-icons{
    padding: 0px 40px;
}
.about-con .col-sm-2{
    border: 1px solid rgb(53, 51, 51);
    padding: 0px;
}
.about-con h2{
    font-weight: 900;
    font-size: 2rem;
    padding: 20px;
    width: 70%;
    margin: auto;
    margin-bottom: 40px;
}
.about-con .icon-div{
    width: 80%;
    margin: 10px auto;
}
.about-con .item-div{
    padding: 0px;
    height: 100%;
    width: 100%;
}
.about-con .item-div .text{
    width: 100%;
    padding: 4px;
}
.about-con p{
    font-weight: bolder;
    width: 100%;
    padding: 0px;
}

@media (max-width: 700px) {
    .about-con{
        padding: 40px 10px;
    }
    .about-con h2{
        font-size: 1.7rem;
        padding: 10px;
        width: 100%;
    }
    .about-con .col-sm-2{
        padding: 10px;
        width: 240px;
        height: 190px;
        margin: auto;
        width: 50%;
    }
    .about-con .icon-div{
        width: 50%;
        margin: 10px auto;
    }
    .about-icons{
        padding: 0px;
    }
    .about-con p{
        font-size: 1rem;
        width: 100%;
    }
}
@media (max-width: 1024px)  {
    .about-con h2{
        font-size: 2rem;
        width: 82%;
    }
}

@media (max-width: 700px) {
    .about-con h2{
        font-weight: 900;
        font-size: 1.7rem;
        width: 100%;
    }
}