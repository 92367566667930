.partner-con{
    padding: 60px 20px;
    background-color: rgb(243, 242, 242);
    font-family: 'DM Sans', sans-serif;
}
.partner-con h2{
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bolder;
    padding: 14px;
}
.partner-con .iconColored-div{
    height: 80px;
    width: 25%;
    padding: 10px;
    margin: auto;
    margin-top: 20px;
    transition: .8s ease-in-out;
}
.partner-con .plus{
    font-weight: bolder;
    font-size: 3rem;
}
.partner-con .partnerIconDiv{
    border: 2px solid #ccc;
    background-color: #fff;
    width: 90%;
    margin: auto;
    padding: 10px;
}
.partner-con .icon-div{
    height: 80px;
    width: 100%;
    margin: auto;
    background-color: #fff;
    border-left: 2px solid #ccc;
    transition: .8s ease-in-out;
}
.partner-con .nb{
    border-left: 2px solid white;
}
.partner-con .icon-div .img-fluid{
    height: 100%;
    width: 100%;
    object-fit: contain;
    right: 0;
    left: 0;
    top: 0;
}
@media (max-width: 700px) {
    .partner-con{
        padding: 40px 20px;
    }
    .partner-con .iconColored-div{
        width: 50%;
    }
    .partner-con .partnerIconDiv{
        width: 100%;
    }
    .partner-con .icon-div{
        height: 80px;
        padding: 2px;
    }
}