.footer{
    background-color: black;
    color: white;
    padding: 40px;
}
.footer .footer-brand{
    width: 50%;
    padding: 10px;
}
.footer .ft-txt{
    padding: 20px;
    width: 70%;
    font-size: .9rem;
}
.footer .social-icons-div{
    padding: 20px;
    display: flex;
    width: 50%;
}
.footer .social-icons-div .fab{
    background-color: white;
    color: black;
    margin: auto;
    height: 40px;
    width: 40px;
    font-size: 1.6rem;
    border-radius: 50%;
    padding: 8px;
}
.footer .social-icons-div .fab:hover{
    background-color: #ccc;
    transform: translateY(-1.2px);
}
.footer .ft-links{
    padding-top: 30px;
}
.footer .ft-links h3{
    padding-bottom: 20px;
}
.footer .flex-column .nav-link{
    padding: 0px;
    font-size: 1rem;
    color: white;
}
.footer .ft-mail{
    padding: 0px;
    margin-bottom: 20px;
}
.footer .ft-mail p{
    padding: 0px;
    margin: 0px;
    font-size: 1rem
    ;
}
.footer .ft-ld-part{
    padding: 0px;
}
.footer .ft-ld-part h3{
    padding: 0px;
    margin-bottom: 0px;
}
.footer .ft-ld-part .footer-brand{
    width: 70%;
    padding-left: 0px;
    margin-top: 0px;
}
@media (max-width: 700px) {
    .footer{
        padding: 40px 10px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .footer .footer-brand{
        width: 80%;
        margin: auto;
    }
    .footer .ft-txt{
        width: 100%;
    }
    .footer .social-icons-div{
        padding: 20px;
        width: 80%;
        margin: auto;
    }
}

 