.carfax{
    padding: 0px;
    width: 90%;
    margin-left: 140px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    
}
.carfax .info{
    padding: 0px;
}