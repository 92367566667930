@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible&family=Open+Sans:wght@700&family=Patua+One&family=Secular+One&display=swap');

html{
    scroll-behavior: smooth;
}
body{
    font-family: 'DM Sans', sans-serif;
}
.con-fluid{
    padding: 0px;
    background-color: #FFFF00;
    margin-top: 0px;
    border: 1px solid #FFFF00;
}
.con-af-fluid{
    padding: 0px;
    margin-top: 0px;
}
.Navbar{
    padding: 1px;
    height: 90%;
    margin: 4px;
    transition: .8s ease-in-out;
    background-color: #FFFF00;
}
.navbar-brand{
    font-size: 2.1rem;
    box-sizing: border-box;
    padding: 10px;
    width: 250px;
}
.toggle-icon{
    display: none;
}
@media (max-width: 700px) {
    .toggle-icon{
        display: block;
        font-size: 2.5rem;
        position: absolute;
        top: 18px;
        right: 10px;
        margin-right: 0px;
    }
}
#basic-navbar-nav{
    margin-left: 320px;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
}
#basic-navbar-nav .flag{
    width: 50px;
    height: 30px;
    margin-left: 30px;
    margin-top: 10px;
    display: flex;
}
#basic-navbar-nav .flag .img-fluid{
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    right: 0;
    left: 0;
}
#basic-navbar-nav a{
    margin: auto 10px;
    color: black;
    font-size: 1.2rem;
    font-weight: bolder;
    font-family: 'DM Sans', sans-serif;
}
#basic-navbar-nav a::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width .3s;
}
#basic-navbar-nav a:hover{
    color: black;
}
#basic-navbar-nav a:hover::after{
    width: 100%;
}
@media (max-width: 700px) {
    .con-fluid{
        padding: 0px;
    }
    .con-af-fluid{
        padding: 0px;
    }
    .Navbar{
        padding: 10px;
        height: 90%;
        margin: 4px;
    }
    .navbar-brand{
        font-size: 2.1rem;
        box-sizing: border-box;
        padding: 4px;
        width: 200px;
    }
    #basic-navbar-nav{
        margin-left: 0px;
        width: 100%;
    }
    #basic-navbar-nav a{
        font-size: 1.2rem;
        font-weight: bolder;
    }
    .nav{
        width: 100%;
    }
    .search-nav .form-control{
        margin: auto 1px;
    }
    .navbar-toggler{
        color: black;
    }
    #basic-navbar-nav .flag{
        margin-left: 10px;
        margin-top: 18px;
    }
}
/* @media (max-width: 1024px) {
    #basic-navbar-nav{
        margin-left: 200px;
    }
} */