body{
    font-family: 'DM Sans', sans-serif;
}
.jumbotron{
    background-color: white;
    background-image: url('../../assests/Checkman Web Assets/checkman_image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 50px 0px;
    height: 650px;
    font-family: 'DM Sans', sans-serif;
    color: black;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.jumbotron h1{
    font-size: 4rem;
    font-weight: bolder;
    font-family: 'DM Sans', sans-serif;
}
.jumbotron h5{
    font-size: 1.8rem;
    width: 80%;
    text-align: center;
    margin: auto;
}

.jumbotron .form-con{
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    margin: auto;
    margin-top: 220px;
}
.jumbotron .form-con .form-control{
    width: 73%;
    padding: 10px;
    margin: 10px auto;
    border-radius: 15px;
    border: 2px solid gray;
    color: black;
    background-color: transparent;
    font-size: 1.8rem;
    text-align: center;
}
.jumbotron .form-con .form-btn{
    display: flex;
    justify-content: center;
    width: 74%;
    margin: auto;
    padding: 0px;
}
.jumbotron .form-con .form-btn .btn{
    background-color: #FFFF00;
    margin: auto;
    width: 49%;
    color: black;
    font-size: 1.2rem;
    padding: 14px;
    font-weight: bolder;
    border-radius: 10px;
    border: 1px solid #838306;
}
.jumbotron .form-inline .btn:hover{
    background-color: #dfdf0b;
    border: #dfdf0b;
    transform: translateY(1.2px);
}
.errorM{
    color: red;
    font-weight: bolder;
    transition: .8s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;
}
.successM{
    color: green;
    font-weight: bolder;
    transition: .8s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;
}
@media (max-width: 700px) {
    .jumbotron .container{
        padding: 0px 10px;
    }
    .jumbotron{
        background-size: contain;
        background-position: center;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 600px;
    }
    .jumbotron h1{
        font-size: 3rem;
        font-weight: bolder;
    }
    .jumbotron h5{
        font-size: 1.1rem;
    }
    .jumbotron .form-con{
        padding: 20px 0px;
        margin-top: 80px;
    }
    .jumbotron .form-con .form-control{
        width: 90%;
        padding: 6px;
        background-color: transparent;
        font-size: 1rem;
        padding: 14px;
        text-align: center;
    }
    .jumbotron .form-con .form-btn{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .jumbotron .form-con .form-btn .btn{
        background-color: #FFFF00;
        margin: 4px auto;
        width: 100%;
        color: black;
        font-size: 1rem;
        padding: 14px;
        font-weight: bolder;
        border-radius: 10px;
        border: 1px solid #838306;
    }

}
/* @media (max-width: 1024px) {
    .jumbotron .form-con{
        padding: 0px;
        margin-top: 140px;
    }
} */