.login-con{
    padding: 40px 20px;
    font-family: 'DM Sans', sans-serif;
    height: 90vh;
}

.login-con h2{
    color: black;
    /* color: #FFFF00; */
    font-weight: 900;
    padding: 10px 0px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.login-con .form-group{
    padding: 8px 0px;
}
.login-con .form-group label{
    color: gray;
}
.login-con .form-group .form-control{
    border-radius: 5px;
    padding: 10px;
    border: 1px solid gray;
}
.login-con .form-group .btn{
    width: 100%;
    border-radius: 5px;
    color: white;
    font-weight: 900;
    background-color: black;
    border: 1px solid black;
    padding: 10px;
}
.login-con .form-group .btn:hover{
    background-color: #dfdf0b;
    border: 1px solid #dfdf0b;
    color: black;
    transform: translateY(1.2px);
}
.login-con .modal-btn{
    background-color: white;
    color: black;
    font-weight: bolder;
    border: 1px solid white;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
}
.login-con .modal-btn:focus{
    border: 1px solid white;
    box-shadow: 2px 2px 2px white;
}
.login-con .modal-btn:hover{
    color: gray;
}
.modal-div{
    width: 400px;
    height: 350px;
    margin: 5% auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    right: 0%;
    top: 10%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid white;
}
.modal-title{
    font-size: 1.2rem;
    font-weight: bolder;
}
.modal-div h2{
    font-weight: bolder;
    color: black;
    font-size: 1.8rem;
}
.modal-div .form-group{
    padding: 5px;
}
.modal-div .form-control{
    border-radius: 10px;
    padding: 10px;
    border: 2px solid gray;
}
.modal-div .btn{
    color: white;
    font-weight: 900;
    background-color: black;
    border: 1px solid #838306;
    border-radius: 10px;
    padding: 15px 10px;
    width: 100%;
}
.modal-div .btn:hover{
    background-color: #dfdf0b;
    border: #dfdf0b;
    color: black;
    transform: translateY(1.2px);
}
.modal-div .mod-body{
    border: 1px solid white;
    padding-top: 30px;
}
.modal-div span{
    cursor: pointer;
    font-size: 1rem;
    padding: 3px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: black;
    color: white;
    position: absolute;
    right: 20px;
    top: 5px;
    font-family: 'Righteous', cursive;

}
.error{
    color: red;
    transition: .8s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(235, 194, 194);
    border-radius: 4px;
    padding: 8px;
}
.success{
    color: green;
    transition: .8s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(173, 233, 173);
    border-radius: 4px;
    padding: 8px;
}
.resend{
    color: black;
    transition: .8s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #ccc;
    border-radius: 4px;
    padding: 8px;
    margin-top: 10px;
}
.googlediv{
    margin-bottom: 100px;
    padding: 2px;
    cursor: pointer;
    transition: .4s ease-in-out;
    border-radius: 5px;
    font-family: 'DM Sans', sans-serif;
}
.googlediv:hover{
    transform: translateY(-1px);
}
.googlediv .btn{
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40px;
    width: 100%;
    /* width: 340px; */
    font-size: 1rem;
}
.googlediv .btn .img-fluid{
    height: 38px;
    width: 20%;
    object-fit: contain;
    top: 0;
    left: 0;
    right: 0;


}
@media (max-width: 700px) {
    .login-con{
        padding: 40px;
    }
    .login-con .form-group .form-control{
        border: 1px solid #ccc;
    }
    .modal-div{
        width: 90%;
        margin: 20% auto;
    }
    .modal-div .mod-body{
        margin-top: 10px;
    }
    .modal-div span{
        top: 0px;
        right: 10px;
    }
    .googlediv .btn{
        width: 100%;
        height: 40px;
    }
}