*{
    padding: 0px;
    margin: 0px;
}
body::-webkit-scrollbar{
    width: 4px;
}
body::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px #A7D7C5; 
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb{
    background: rgb(240, 235, 235); 
    border-radius: 10px;
}
body::-webkit-scrollbar-thumb:hover{
    background: #ccc; 
}

* {
    box-sizing: border-box;
}