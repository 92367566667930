.CTA-con{
    background-color: #ccc;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../assests/Checkman\ Web\ Assets/Bottom-Section-Background-Image.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 120px 40px;
    height: 600px;
    color: white;
}
.CTA-con h1{
    font-weight: bolder;
    font-size: 3.5rem;
}
.CTA-con p{
    font-size: 1.8rem;
    width: 70%;
    margin: auto;
    padding: 10px;
}
.CTA-con .form-group{
    display: flex;
    width: 90%;
    margin: auto;
}
.CTA-con .form-con .form-control{
    width: 65%;
    margin: auto;
    padding: 15px;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 10px;
}
.CTA-con .form-con .btn{
    width: 30%;
    background-color: #FFFF00;
    border: 1px solid #FFFF00;
    color: black;
    font-weight: bold;
    margin: auto;
    padding: 15px;
    font-size: 1.4rem;
    border-radius: 10px;
}
.CTA-con .btn:hover{
    background-color: #dfdf0b;
}
.footer-hero{
    padding: 0px;
    position: relative;
    height: 1020px;
}
.review-con{
    padding: 40px 20px;
    color: black;
    background-color: white;
    width: 60%;
    position: absolute;
    left: 20%;
    bottom: 2%;
    z-index: 1;
    margin: auto;
}
.review-con h1{
    font-size: 3.5rem;
    font-weight: 900;
    margin-bottom: 30px;
}
.carousel{
    height: 400px;
    width: 100%;
    margin: auto;
}
.carousel .carousel-item{
    height: 400px;
    color: black;
    padding: 20px;
    margin-bottom: 0px;
}
.carousel .carousel-item .col-sm-4{
    height: 350px;
    padding: 10px;
}
.carousel .carousel-item .col-sm-4 .caption{
    margin: 0px auto;
    color: black;
    padding: 20px 4px;
}
.carousel .carousel-item .img-div{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0px auto;
}
.carousel .carousel-item .img-div .img-fluid{
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    right: 0;
    left: 0;
}
.carousel .carousel-item .col-sm-4 .caption h3{
    font-size: 1rem;
    font-weight: 900;
    margin-bottom: 10px;
}
/* .carousel .carousel-item .col-sm-4 .caption p{
    font-weight: lighter;
} */
@media (max-width: 800px) {
    .review-con{
        bottom: 1%;
    }
    .footer-hero{
        padding: 0px;
        position: relative;
        height: 1080px;
    }
}
@media (max-width: 700px) {
    .CTA-con{
        padding: 40px 10px;
        height: auto;
    }
    .CTA-con h1{
        font-weight: bolder;
        font-size: 3rem;
    }
    .CTA-con p{
        font-size: 1.2rem;
        width: 100%;
    }
    .CTA-con .form-group{
        flex-direction: column;
        width: 100%;
    }
    .CTA-con .form-con .form-control{
        width: 100%;
        margin: 10px auto;
        font-size: 1rem;
        padding: 14px;
        text-align: center;
    }
    .CTA-con .form-con .btn{
        width: 100%;
        padding: 12px;
    }
    .footer-hero{
        padding: 0px;
        position: unset;
        height: auto;
    }
    .review-con{
        padding: 20px 10px;
        width: 100%;
        position: unset;
        z-index: unset;
    }
    .review-con h1{
        font-size: 2.5rem;
    }
    .carousel{
        height: 1000px;
        width: 100%;
        margin: auto;
    }
    .carousel .carousel-item{
        height: 1000px;
        color: black;
        padding: 20px;
        margin-bottom: 0px;
    }
    .carousel .carousel-item .col-sm-4{
        height: 300px;
        padding: 10px;
    }
    .carousel .carousel-item .col-sm-4 .caption{
        margin: 0px auto;
        color: black;
        padding: 20px 4px;
    }
}

