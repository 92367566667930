.price-con{
    padding: 80px 20px;
}
.price-con .col-sm-4{
    height: 400px;
}
.price-con a{
    color: black;
}
.price-con a:hover{
    color: black;
}
.price-con h1{
    font-weight: 900;
    font-size: 3.5rem;
    margin-bottom: 30px;
}
.price-con .card{
    border: 4px solid #FFFF00;
    width: 100%;
    height: 300px;
    margin: 30px auto;
    padding: 10px;
    box-shadow: 6px 6px 12px #ccc;
    transition: .5s ease-in-out;
}
.price-con .card:hover{
    box-shadow: 8px 8px 16px #ccc;
    transform: translateY(-1.2px);
}
.price-con .card h2, .price-con .card h5{
    font-weight: bolder;
    margin: 10px auto;
}
.price-con .card h2{
    font-size: 2.5rem;
}
.price-con .card .logo{
    padding: 5px;
    height: 50px;
    width: 80%;
    margin: 10px auto;
}
.price-con .card .logo .img-fluid{
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 0;
    right: 0;
    left: 0;
}
.price-con .card .btn{
    border-radius: 30px;
    background-color: rgb(252, 157, 74);
    border: 1px solid rgb(252, 157, 74);
    width: 70%;
    padding: 8px;
    margin: auto;
    color: white;
}
.price-con .card .btn:hover{
    background-color: rgb(226, 134, 53);
}
.price-con .middle-col{
    padding: 0px;
}
.price-con .middle-card{
    padding: 0px;
    height: 360px;
    width: 100%;
    margin: 0px auto;
}
.price-con .middle-card .card-body{
    padding: 0px;
}
.price-con .middle-card .recommend{
    background-color: #FFFF00;
    width: 100%;
    padding: 5px;
    font-weight: bolder;
    font-size: .9rem;
}
.price-con .middle-card h5{
    margin-top: 20px;
}
.price-con .middle-card h2{
    margin-bottom: 0px;
}
.price-con .middle-card small{
    color: gray;
}
.price-con .middle-card .package{
    margin-top: 10px;
    margin-bottom: 0px;
}
.price-con .middle-card .btn{
    border-radius: 30px;
    background-color: rgb(252, 157, 74);
    border: 1px solid rgb(252, 157, 74);
    width: 60%;
    padding: 8px;
    margin: auto;
    color: white;
}
.error{
    color: red;
    transition: .8s ease-in-out;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 4px;
}
@media (max-width: 700px) {
    .price-con{
        padding: 40px 10px;
        position: relative;
    }
    .price-con .col-sm-4{
        height: auto;
    }
    .price-con h1{
        font-size: 2.5rem;
        margin-bottom: 10px;
    }
    .price-con .card{
        margin: 10px auto;
    }
    .price-con .middle-card{
        margin: 10px auto;
    }
    .error{
        position: fixed;
        top: 8px;
        right: 20%;
    }
}

/* @media (max-width: 1024px) {
    .price-con .card h2{
        font-size: 1.4rem;
        margin-bottom: 10px;
    }
    .price-con .card{
        height: 300px;
    }
    .price-con .middle-card{
        height: 360px;
    }
    .price-con .card .btn{
        width: 90%;
    }
} */