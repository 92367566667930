.freqQ{
    padding: 60px 40px;
    font-family: 'DM Sans', sans-serif;
    background-color: rgb(237, 235, 235);
}
.freqQ h1{
    font-weight: 900;
    font-size: 3.5rem;
    padding-bottom: 30px;
}
.freqQ .accordion{
    width: 70%;
    margin: auto;
    border: 1px dotted rgb(237, 235, 235);
}
.freqQ .accordion h4{
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}
.freqQ .accordion p{
    padding: 10px 20px;
    font-size: 1rem;
    font-family: 'DM Sans', sans-serif;
}
.freqQ .accordion pre{
    font-size: 1rem;
    font-family: 'DM Sans', sans-serif;
}
.freqQ .card-header{
    background-color: rgb(237, 235, 235);
    border: 1px solid rgb(237, 235, 235);
    border-bottom: 2px solid black;
    padding: 6px;
    transition: .4s ease-in-out;
    display: flex;
    justify-content: space-between;
}
.freqQ .card-header .toggle-indicator{
    margin-right: 10px;
    transition: .5s ease-in-out;
}
.freqQ .card-header:hover{
    cursor: pointer;
    background-color: rgb(230, 227, 227);
    transition: .4s ease-in-out;
}
.freqQ .accord-tog:hover{
    cursor: pointer;
    background-color: rgb(230, 227, 227);
    border: 1px solid rgb(230, 227, 227);
    transition: .4s ease-in-out;
}
.freqQ .accord-tog{
    background-color: rgb(237, 235, 235);
    border: 1px solid rgb(237, 235, 235);
    width: 100%;
}
@media (max-width: 700px) {
    .freqQ{
        padding: 40px 10px;
        font-family: 'DM Sans', sans-serif;
    }
    .freqQ h1{
        font-size: 2rem;
    }
    .freqQ .accordion{
        width: 100%;
        margin: auto;
    }
    .freqQ .card-header .toggle-indicator{
        margin-top: 10px;
    }
}

