.loader{
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 7;
    /* width: 80%;
    top: 0; */
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    /* height: 100vh; */
    position: fixed;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 10%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    /* background: #dfdf0b; */
    background-color: #FFFF00;
    box-shadow: 3px 3px 8px #ccc;
    z-index: 4;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
.overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 130vh;
    /* background-color: rgba(0, 0, 0, 0.4); */
    /* background-color: black; */
    background-color: white;

}
